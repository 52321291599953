import { AmacUrl, Page } from 'src/common/routes';
import { Navigate, useNavigate } from 'react-router-dom';
import useToken from 'src/common/useToken';
import moment from 'moment';
import { setGlobalState, useGlobalState } from 'src/state/globalstate';

export default function ProtectedRoute(props) {
  const { token } = useToken();
  const email = localStorage.getItem('email');
  let navigate = useNavigate();

  const Expiretime = Number(localStorage.getItem('Expiretime'));
  const newtime = Date.now();

  const startDate = moment(Expiretime);
  const timeEnd = moment(newtime);
  const diff = timeEnd.diff(startDate);
  const diffDuration = moment.duration(diff);
  const diffMin = diffDuration.minutes();
  //alert(diffMin);
  //alert(diffMin);

  if (diffMin > 30) {
    localStorage.clear();
    setGlobalState('loggedin', false);
    setGlobalState('sessionerror', 'Unauthorized');
    return <Navigate to={Page.Stats} replace />;
  }

  const URL = window.location.href;
  const path = window.location.pathname;

  console.log('path => ' + path);

  if (!token) {
    return <Navigate to={Page.Statistics} replace />;
  }

  if (path == '/') {
    return <Navigate to={Page.Statistics} replace />;
  }

  if (
    token &&
    !URL.includes(AmacUrl.Statistics) &&
    !URL.includes(AmacUrl.Stats) &&
    !URL.includes(AmacUrl.CommunityDemographicsHome)
  ) {
    // if(URL.includes(AmacUrl.Statistics)) {
    //   console.log('Avoid redirect 01')
    //   return
    //   // return window.open(AmacUrl.Statistics, '_self');
    // }

    console.log('ProtectedRoute URL => ' + URL);
    if (
      (URL.includes('users-management') ||
        URL.includes('search-voters-dynamodb') ||
        URL.includes('search-voters-clickhouse')) &&
      email != 'nazeer6236@gmail.com' &&
      email != 'yaamin6236@gmail.com' &&
      email != 'shaheryar@inabia.com'
    ) {
      console.log('Not Allowed');
      return <Navigate to={AmacUrl.Dashboard} replace />;
    }
    // return <Navigate to={AmacUrl.Dashboard} replace />;
  }

  return props.children;
}

export function SessionMaintained(props) {
  const { token } = useToken();
  const URL = window.location.href;
  console.log('Session URL => ' + URL);

  // if(URL.includes(AmacUrl.Statistics)) {
  //   console.log('Avoid redirect 02')
  //   return;
  //   // return window.open(AmacUrl.Statistics, '_self');
  // }

  if (
    token &&
    !URL.includes(AmacUrl.Statistics) &&
    !URL.includes(AmacUrl.Stats) &&
    !URL.includes(AmacUrl.CommunityDemographicsHome)
  ) {
    return window.open(AmacUrl.Dashboard, '_self');
  }

  return props.children;
}
