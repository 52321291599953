import { datadogRum } from "@datadog/browser-rum";

try {
    datadogRum.init({
        applicationId: 'd2214ec8-cc4c-420a-b130-7ae04f6616d4',
        clientToken: 'pubd3cbfb0cd5b0d6a3754206931a25de43',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'us5.datadoghq.com',
        service: 'amac-react',
        env: '<ENV_NAME>',
        // Specify a version number to identify the deployed version of your application in Datadog
        version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        defaultPrivacyLevel: 'mask-user-input',
        trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
    });
    
    // Start tracking user sessions
    datadogRum.startSessionReplayRecording();
    } catch(e) {

    }



export default datadogRum;